import DirectoryTree from "antd/lib/tree/DirectoryTree";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { apiAxios } from "../../api";
import { SidebarSection } from "../../assets/styles/common";
import { Directory } from "../../dto/Directory";

const Directories = ({ type }: any) => {
  const history = useHistory();
  const location = useLocation();
  const [directories, setDirectories] = useState<Directory[]>([]);
  const [postId, setPostId] = useState<number | null>(null);

  useEffect(() => {
    // 마운트 후 또는 경로 변경 후 상태 업데이트
    const updatePath = () => {
      const postId = parsePostId(location.pathname);
      if (!postId) {
        return;
      }

      setPostId(Number(postId));
    };

    // requestAnimationFrame을 활용해 DOM 업데이트 후 실행
    const rafId = requestAnimationFrame(updatePath);

    return () => cancelAnimationFrame(rafId); // cleanup
  }, [location]);

  const findSelectedDirectories = (
    data: Directory[],
    postId: number,
  ): number[] => {
    const path: number[] = [];

    const dfs = (directories: Directory[], currentPath: number[]): boolean => {
      for (const dir of directories) {
        const newPath = [...currentPath, dir.id];

        // 게시물이 현재 디렉토리에 존재하는 경우
        if (dir.Posts.some((post) => post.id === postId)) {
          path.push(...newPath);
          return true;
        }

        // 하위 디렉토리 재귀 탐색
        if (dir.Directories && dfs(dir.Directories, newPath)) {
          return true;
        }
      }
      return false;
    };

    dfs(data, []);

    return path.length > 0 ? path : []; // 찾지 못한 경우 null 반환
  };

  const findOneDepthDirectory = (data: Directory[]): number[] =>
    data.filter((it) => !Boolean(it.directory_id)).map((it) => it.id);

  const findDirectoryKeys = (
    data: Directory[],
    postId: number | null,
  ): string[] => {
    const oneDeptyDirectoryIds = findOneDepthDirectory(data);
    if (!postId) {
      return oneDeptyDirectoryIds.map(createDirectoryKey);
    }

    const selectedDirectories = findSelectedDirectories(data, postId);

    return [
      ...selectedDirectories.map(createDirectoryKey),
      ...oneDeptyDirectoryIds.map(createDirectoryKey),
    ];
  };

  const getDirectories = async (): Promise<void> => {
    const { data } = await apiAxios.get(`/api/post/directories?type=${type}`);
    setDirectories(data.data.directories);
  };

  const parsePostId = (path: string): string | null => {
    if (path.startsWith("/post/dev/")) {
      return path.split("/")[3];
    }

    if (path.startsWith("/post/diary/")) {
      return path.split("/")[3];
    }

    return null;
  };

  const onSelect = (selectedKeys: any, event: any) => {
    const postId = event.node.postId;
    if (postId) {
      // window.location.href = `/post/${type}/${postId}`;
      history.push(`/post/${type}/${postId}`);
    }
  };

  const createDirectoryKey = (directoryId: string | number) =>
    `directory-${directoryId.toString()}`;

  const createPostKey = (postId: string | number) =>
    `post-${postId.toString()}`;
  useEffect(() => {
    getDirectories();
  }, [type]);

  const makeDirectoryData = (directory: any) => {
    const style = {
      fontSize: "14px",
      padding: "0 0 2.5px",
    };

    return {
      title: directory.name,
      key: createDirectoryKey(directory.id),
      isLeaf: false,
      children: [
        ...(directory.Directories ?? []).map((d: any) => makeDirectoryData(d)),
        ...directory.Posts.map((post: any) => ({
          title: post.title,
          key: createPostKey(post.id),
          isLeaf: true,
          postId: post.id,
          style,
        })),
      ],
      style,
    };
  };

  return (
    <>
      <style>
        {`
          .ant-tree .ant-tree-node-content-wrapper {
            padding: 0px
          }
        `}
      </style>
      {directories.length > 0 && (
        <SidebarSection>
          {/*<h4>Directories</h4>*/}
          <DirectoryTree
            defaultSelectedKeys={postId ? [createPostKey(postId)] : []}
            switcherIcon={<></>}
            defaultExpandedKeys={findDirectoryKeys(directories, postId)}
            onSelect={onSelect}
            treeData={directories.map(makeDirectoryData)}
          />
        </SidebarSection>
      )}
    </>
  );
};
export default Directories;
