import { Link } from "react-router-dom";
import {
  NavigationContainer,
  NavigationSide,
  PostSummary,
  PostTitle,
} from "../../assets/styles/post-navigation-style";

interface PrevNextPost {
  id: number;
  title: string;
  summary: string;
}

interface PostNavigationProps {
  prevPost: PrevNextPost | null;
  nextPost: PrevNextPost | null;
  type: string;
}

const PostNavigation = ({ prevPost, nextPost, type }: PostNavigationProps) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <h3>이것도 읽어보세요</h3>
      <NavigationContainer>
        {prevPost ? (
          <NavigationSide>
            <Link to={`/post/${type}/${prevPost.id}`}>
              <PostTitle>{prevPost.title}</PostTitle>
            </Link>
            <PostSummary>{prevPost.summary}</PostSummary>
          </NavigationSide>
        ) : (
          <NavigationSide />
        )}

        {nextPost ? (
          <NavigationSide align="right">
            <Link to={`/post/${type}/${nextPost.id}`}>
              <PostTitle>{nextPost.title}</PostTitle>
            </Link>
            <PostSummary>{nextPost.summary}</PostSummary>
          </NavigationSide>
        ) : (
          <NavigationSide align="right" />
        )}
      </NavigationContainer>
    </div>
  );
};

export default PostNavigation;
