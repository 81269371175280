import mipong from "../../assets/images/mipong.png";

import { BackTop, Divider, Skeleton } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../..";
import { apiAxios, apiUrl } from "../../api";
import PostDetailStyle from "../../assets/styles/post-detail-style";
import { Post } from "../../dto/Post";
import { showMessage, showSuccessMessage } from "../../util";
import GiscusComments from "../comment/GiscusComments";
import MainContent from "../common/MainContent";
import MarkdownViewer from "../common/MarkdownViewer";
import WysiwygViewer from "../common/WysiwygViewer";
import useConfirm from "../hooks/UseConfirm";
import PDFViewer from "../common/PDFViewer";
import CommentList from "../comment/CommentList";
import CommentForm from "../comment/CommentForm";
import PostNavigation from "../common/PostNavigation";
import TableOfContents from "../common/TableOfContents";

interface PrevNextPost {
  id: number;
  title: string;
  summary: string;
}

const PostDetail = ({ match }: any) => {
  const { type, postId } = useMemo(() => match.params, [match.params]);
  const { isDarkMode, isAdmin, userId, isLoggedIn, isMobile, isDesktop } =
    useContext(AppContext);
  const [post, setPost] = useState<Post>();
  const history = useHistory();
  const [, setTags] = useState<string[]>([]);
  const [comments, setComments] = useState<any[]>([]);
  const [directoryNames, setDirectoryNames] = useState<string[]>([]);
  const [prevPost, setPrevPost] = useState<PrevNextPost | null>(null);
  const [nextPost, setNextPost] = useState<PrevNextPost | null>(null);

  const isDiary = type === "diary";

  const getPost = async () => {
    setPost(undefined);
    const { data } = await apiAxios
      .post(`/api/post/${postId}`)
      .catch((error: any) => {
        showMessage(error.response.data.data);
        window.location.href = "/";

        return error.response.data;
      });
    setPost(data.data.post);
    setTags(data.data.tags);
    setComments(data.data.comments);
    setDirectoryNames(data.data.directoryNames);
  };

  const getPrevNextPost = async () => {
    try {
      const { data } = await apiAxios.get(`/api/post/${postId}/prev-next`);
      if (data.success) {
        setPrevPost(data.data.prevPost);
        setNextPost(data.data.nextPost);
      }
    } catch (error) {
      console.error("Failed to fetch prev/next posts:", error);
    }
  };

  const sharePost = () => {
    const url = `${window.location.origin}/share/post/${post?.id}`;
    navigator.clipboard.writeText(url);

    showSuccessMessage("Share Link Copied");
  };

  const shareContent = () => {
    const url = `${window.location.origin}/share/post/${post?.id}/content`;
    navigator.clipboard.writeText(url);

    showSuccessMessage("Share Link Copied");
  };

  const deletePost = useConfirm("Delete?", async () => {
    try {
      const hasPdfFile = post?.contents_type === "pdf" && post?.file_url;

      if (hasPdfFile) {
        await apiAxios.post(`${apiUrl}/upload/pdf/delete`, {
          id: Number(postId),
          url: post?.file_url,
        });
      }

      const { data } = await apiAxios.post(`/api/post/delete/${postId}`);

      if (data.success) {
        window.location.href = `/post/${type}`;
      }
    } catch (error) {
      showMessage(error.response.data.data);

      return;
    }
  });

  const gotoList = () => {
    window.scrollTo(0, 0);
    history.push(`/post/${type}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPost();
    getPrevNextPost();
  }, [postId, isDarkMode]);

  return (
    <MainContent type={type}>
      <Helmet>
        <title>{post?.title}</title>
        <meta property="og:title" content={post?.title} />
        <meta property="og:description" content={post?.summary ?? ""} />
        <meta property="og:image" content={mipong} />
      </Helmet>
      <PostDetailStyle />
      <BackTop />
      <h2 style={{ margin: "5px 0px" }}>{post?.title}</h2>
      {/*{*/}
      {/*  <Breadcrumb separator=">">*/}
      {/*    {directoryNames.map((name, idx) => (*/}
      {/*      <Breadcrumb.Item key={`directory-${idx}`}>*/}
      {/*        {name}*/}
      {/*      </Breadcrumb.Item>*/}
      {/*    ))}*/}
      {/*  </Breadcrumb>*/}
      {/*}*/}
      <div>
        <span style={{ float: "right" }}>
          {post?.regdate.slice(0, 10).replace(/-/g, "")}
        </span>
      </div>
      {post && (
        <div>
          <a className={"italic"} onClick={sharePost}>
            공유
          </a>
          {isAdmin && post.is_shareable === true && (
            <>
              &nbsp;
              <a className={"italic"} onClick={shareContent}>
                본문공유
              </a>
            </>
          )}
          {post.contents_type === "pdf" && (
            <>
              &nbsp;
              <a className={"italic"} href={post.file_url}>
                다운로드
              </a>
            </>
          )}
          {(isAdmin || post?.member_id === userId) && (
            <>
              &nbsp;
              <Link className={"italic"} to={`/post/update/${type}/${postId}`}>
                수정
              </Link>
              &nbsp;
              <a className={"italic"} onClick={deletePost}>
                삭제
              </a>
            </>
          )}
          &nbsp;
          <a className={"italic"} onClick={gotoList}>
            목록
          </a>
        </div>
      )}

      <Divider />
      {post ? (
        <>
          {post.contents_type.startsWith("markdown") && isDesktop && (
            <TableOfContents content={post.contents} isDarkMode={isDarkMode} />
          )}
          {post.contents_type.startsWith("wysiwyg") ? (
            <WysiwygViewer
              theme={isDarkMode ? "dark" : "light"}
              contents={post.contents}
            />
          ) : post.contents_type.startsWith("markdown") ? (
            <MarkdownViewer
              theme={isDarkMode ? "dark" : "light"}
              markdown={post.contents}
            />
          ) : (
            <PDFViewer path={post.file_url} />
          )}

          <Divider />

          <PostNavigation prevPost={prevPost} nextPost={nextPost} type={type} />
          <Divider />
          <h5
            style={{ float: "right" }}
            className={"italic black no-margin-bottom"}
          >
            조회 {post?.view_count}
          </h5>
          <br />
          <h5
            style={{ float: "right" }}
            className={"italic underline black no-margin-bottom"}
            onClick={gotoList}
          >
            목록
          </h5>

          {/*<h2 style={{ marginTop: "30px" }}>Comments</h2>*/}
          {isDiary && isLoggedIn && (
            <>
              <CommentList comments={comments} />
              <CommentForm postId={postId} />
            </>
          )}
          {!isDiary && <GiscusComments postId={postId} />}
        </>
      ) : (
        <>
          <Skeleton active paragraph={{ rows: 5 }} />
          <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton active paragraph={{ rows: 10 }} />
        </>
      )}
    </MainContent>
  );
};

export default PostDetail;
