import { Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import About from "../sidebar/About";
import Directories from "../sidebar/Directories";
import Tags from "../sidebar/Tags";

const MainContent = ({ children, type }: any) => {
  return (
    <Content style={{ padding: "0 10px" }}>
      <Row>
        <Col xs={0} md={8} lg={8} xl={8}>
          <div
            style={{
              // 왼쪽 컬럼이 항상 글에 나타나도록 설정하기
              position: "sticky",
              top: "0px",
              // 왼쪽 컬럼과 본문 컬럼의 스크롤바를 분리하기
              overflow: "auto",
              height: "100vh",
              // 스크롤바 감추가
              scrollbarWidth: "none" /* For Firefox */,
              msOverflowStyle: "none" /* For Internet Explorer and Edge */,
            }}
          >
            <About />
            <Directories type={type} />
            <Tags type={type} />
          </div>
        </Col>
        {/* <Col xs={0} md={1} lg={1} xl={1} /> */}
        <Col xs={24} md={16} lg={16} xl={16}>
          {children}
        </Col>
      </Row>
    </Content>
  );
};
export default MainContent;
