import { Link } from "react-router-dom";
import { Post } from "../../dto/Post";

interface PostProps {
  post: Post;
  type: string;
}

const PostCard = ({ post, type }: PostProps) => {
  return (
    <>
      <Link to={`/post/${type}/${post.id}`}>
        <span style={{ fontWeight: 600 }}>
          {post.regdate.slice(0, 10).replace(/-/g, "")} - {post.title}
        </span>
        <br />
        <span
          style={{ color: "#000000a6", fontStyle: "italic", fontSize: "0.9em" }}
        >
          {post.summary}
        </span>
        <br />
        <br />
      </Link>
    </>
  );
};
export default PostCard;
