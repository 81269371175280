import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

/**
 * Theme interface for styled components
 */
interface ThemeProps {
  theme: {
    mode: "dark" | "light";
  };
}

/**
 * Styled container for the table of contents
 */
const TOCContainer = styled.div<ThemeProps>`
  position: fixed;
  right: 20px;
  top: 50px;
  width: 290px;
  max-height: 85vh;
  overflow-y: auto;
  padding: 15px;
  border-radius: 5px;
  z-index: 100;

  /* Hide scrollbar but allow scrolling */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

/**
 * Styled title for the table of contents
 */
const TOCTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: bold;
`;

/**
 * Styled list container for TOC items
 */
const TOCList = styled.div<ThemeProps>`
  font-size: 14px;

  a {
    display: block;
    margin-bottom: 4px;
    color: ${(props) => (props.theme.mode === "dark" ? "#e1e1e1" : "#333")};
    text-decoration: none;
  }

  /* Indentation for nested items */
  .toc-level-1 {
    margin-left: 0;
  }
  .toc-level-2 {
    margin-left: 10px;
  }
  .toc-level-3 {
    margin-left: 20px;
  }
  .toc-level-4 {
    margin-left: 30px;
  }
`;

/**
 * Interface representing a single TOC item
 */
interface TOCItem {
  text: string; // Display text for the TOC item
  link: string; // URL or anchor link
  level: number; // Nesting level (1-4)
}

/**
 * Props for the TableOfContents component
 */
interface TableOfContentsProps {
  content: string; // Markdown content to extract TOC from
  isDarkMode?: boolean; // Whether dark mode is enabled
}

/**
 * Extracts table of contents items from markdown content
 *
 * Looks for a section that starts with "Contents" in various formats
 * and extracts the list items that follow it.
 *
 * @param content - Markdown content to extract TOC from
 * @returns Array of TOC items or null if no TOC found
 */
const extractTOCFromContent = (content: string): TOCItem[] | null => {
  // Pattern to match the Contents section in various formats
  // This matches:
  // 1. **_Contents_** or **Contents** or _Contents_
  // 2. Markdown headings: # Contents, ## Contents, ### Contents
  // 3. Followed by a list of markdown links in the format: - [Text](link)

  // Define the different ways "Contents" can appear
  const contentsFormats = [
    // Bold italic: **_Contents_**
    "\\*\\*_Contents_\\*\\*",
    // Bold: **Contents**
    "\\*\\*Contents\\*\\*",
    // Italic: _Contents_
    "_Contents_",
    // Markdown headings: # Contents, ## Contents, ### Contents
    "#+ Contents",
  ].join("|");

  // Pattern for the list of links that follow the Contents section
  const linksPattern =
    "((?:-\\s*\\[.*?\\]\\(.*?\\)\\s*(?:\\n\\s*-\\s*\\[.*?\\]\\(.*?\\)\\s*)*)+)";

  // Combine patterns
  const contentsPattern = new RegExp(
    `(?:${contentsFormats})\\s*${linksPattern}`,
  );

  const match = content.match(contentsPattern);

  // If no TOC section found, return null
  if (!match || !match[1]) {
    return null;
  }

  const tocItems: TOCItem[] = [];
  const lines = match[1].split("\n");

  lines.forEach((line) => {
    // Skip empty lines
    if (!line.trim()) return;

    // Calculate indentation level based on spaces and list markers
    const indentLevel = calculateIndentLevel(line);

    // Extract link and text using regex
    // Format: - [Link Text](link-url)
    const linkMatch = line.match(/-\s*\[(.*?)\]\((.*?)\)/);
    if (linkMatch) {
      const [, text, link] = linkMatch;

      // Ensure the link works with page anchors
      // If link doesn't start with #, add it
      const finalLink = link.startsWith("#") ? link : `#${link}`;

      tocItems.push({
        text,
        link: finalLink,
        level: indentLevel,
      });
    }
  });

  return tocItems.length > 0 ? tocItems : null;
};

/**
 * Calculates the indentation level of a TOC line
 *
 * Uses both space indentation and list marker nesting to determine level
 *
 * @param line - A line from the TOC section
 * @returns The indentation level (1-4)
 */
const calculateIndentLevel = (line: string): number => {
  let indentLevel = 1;

  // Method 1: Check indentation based on leading spaces
  const spaces = line.match(/^\s*/)?.[0].length ?? 0;
  if (spaces > 0) {
    indentLevel = Math.floor(spaces / 2) + 1;
  }

  // Method 2: Check indentation based on nested list format
  // Count the number of dash characters in the list markers
  const dashesMatch = line.match(/^(\s*-\s*)+/);
  if (dashesMatch) {
    const dashes = dashesMatch[0].match(/-/g) || [];
    indentLevel = Math.max(indentLevel, dashes.length);
  }

  // Limit to 4 levels for UI consistency
  return Math.min(indentLevel, 4);
};

/**
 * Renders TOC items as HTML string
 *
 * @param items - Array of TOC items
 * @returns HTML string with anchor tags
 */
const renderTOCItemsToHtml = (items: TOCItem[]): string => {
  return items
    .map(
      (item) =>
        `<a href="${item.link}" class="toc-level-${item.level} italic">${item.text}</a>\n`,
    )
    .join("");
};

/**
 * TableOfContents component displays a floating table of contents
 *
 * It extracts TOC items from markdown content and renders them as a floating sidebar.
 */
const TableOfContents: React.FC<TableOfContentsProps> = ({
  content,
  isDarkMode,
}) => {
  const [tocHtml, setTocHtml] = useState<string>("");
  const tocListRef = useRef<HTMLDivElement>(null);

  // Extract TOC items from content and render them as HTML
  useEffect(() => {
    const tocItems = extractTOCFromContent(content);

    if (tocItems) {
      setTocHtml(renderTOCItemsToHtml(tocItems));
    } else {
      setTocHtml("");
    }
  }, [content]);

  // Don't render if no TOC found
  if (!tocHtml) return null;

  // Create theme object
  const theme = { mode: isDarkMode ? "dark" : "light" };

  return (
    <TOCContainer theme={theme}>
      <TOCTitle>Contents</TOCTitle>
      <TOCList
        ref={tocListRef}
        theme={theme}
        dangerouslySetInnerHTML={{ __html: tocHtml }}
      />
    </TOCContainer>
  );
};

export default TableOfContents;
