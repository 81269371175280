import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import Prism from "prismjs";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-java";
import "prismjs/components/prism-kotlin";
import "prismjs/components/prism-python";
import "prismjs/components/prism-ruby";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-swift";
import "prismjs/components/prism-typescript";
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard";
import "prismjs/prism.js";
import "prismjs/themes/prism.css";

import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";

import { Viewer } from "@toast-ui/react-editor";
import { useEffect, useRef } from "react";

/**
 * Props for the MarkdownViewer component
 */
interface MarkdownViewerProps {
  markdown: string;         // Markdown content to render
  theme?: "light" | "dark"; // Theme for the viewer
  style?: React.CSSProperties; // Optional styling
}

/**
 * MarkdownViewer component renders markdown content using Toast UI Editor
 * 
 * It also adds ID attributes to headings to enable navigation from the TableOfContents
 */
const MarkdownViewer = ({ markdown, theme }: MarkdownViewerProps) => {
  // Reference to the Toast UI Viewer component
  const viewerRef = useRef<Viewer>(null);

  // Add ID attributes to headings after the markdown is rendered
  useEffect(() => {
    if (!viewerRef.current) return;

    // Get the viewer's DOM element
    const viewerEl = viewerRef.current.getRootElement();
    if (!viewerEl) return;

    // Find all heading elements
    const headings = viewerEl.querySelectorAll("h1, h2, h3, h4, h5, h6");

    // Add id attribute to each heading
    headings.forEach((heading: Element) => {
      const text = heading.textContent || "";
      
      // Create an ID that matches the format used in TableOfContents
      // This should match the ID format in the Markdown content
      // Only remove problematic special characters and replace spaces with hyphens
      // Preserve non-ASCII characters like Korean
      const id = text
        .replace(/[^\w\s\p{L}-]/gu, "") // Remove special chars except Unicode letters
        .replace(/\s+/g, "-")           // Replace spaces with hyphens
        .toLowerCase();                  // Convert to lowercase for consistency
      
      heading.setAttribute("id", id);
    });
  }, [markdown]);

  return (
    <Viewer
      ref={viewerRef}
      initialValue={markdown}
      theme={theme}
      plugins={[[codeSyntaxHighlight, { highlighter: Prism }]]}
    />
  );
};

export default MarkdownViewer;
