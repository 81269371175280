import {
  GithubOutlined,
  MailOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Space } from "antd";
import { SidebarSection } from "../../assets/styles/common";
import { apiUrl } from "../../api";

const About = () => {
  return (
    <SidebarSection style={{ paddingLeft: "4px", fontSize: "14px" }}>
      <Space size={5}>
        <PaperClipOutlined />
        <a href={`${apiUrl}/api/rss`}>RSS Feed</a>
      </Space>
      <br />

      <Space size={5}>
        <GithubOutlined />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/h16rkim"
        >
          Github
        </a>
      </Space>
      <br />

      <Space size={5}>
        <MailOutlined />
        <a href={"mailto:h16rkim@gmail.com"}>h16rkim@gmail.com</a>
      </Space>
      <br />
    </SidebarSection>
  );
};
export default About;
