import { BackTop, Divider, Skeleton, Switch } from "antd";
import * as queryString from "query-string";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../..";
import { apiAxios } from "../../api";
import { Post } from "../../dto/Post";
import { showMessage } from "../../util";
import MainContent from "../common/MainContent";
import SearchBar from "../common/SearchBar";
import YearBreadcrumb from "../common/YearBreadcrumb";
import PostCard from "./PostCard";
import { useDisableScrollRestoration } from "../hooks/UseDisableScrollRestoration";

const PostList = ({ match }: any) => {
  const { type } = useMemo(() => match.params, [match.params]);
  const { isActive, isAdmin, isDarkMode } = useContext(AppContext);
  const [posts, setPosts] = useState<Post[]>([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  const query = useMemo(() => queryString.parse(location.search), [location]);

  useDisableScrollRestoration();

  const getPosts = useCallback(
    async (page: number) => {
      setLoading(true);
      try {
        const { data } = await apiAxios.post("/api/post", {
          postType: [type],
          page,
          perpage: 20,
          year: query.year,
          keyword: query.tag,
          search: query.search,
          isVisible: isVisible,
        });

        const previewPosts = data.data.posts.map((post: Post) => {
          post.contents = post.contents.slice(0, 700);
          return post;
        });

        setPosts((prev) =>
          page === 1 ? previewPosts : [...prev, ...previewPosts],
        );
        setHasNext(data.data.hasNext);
      } catch (error: any) {
        showMessage(error.response?.data?.data || "Error fetching posts");
        window.location.href = "/";
      } finally {
        setLoading(false);
      }
    },
    [type, query, isVisible],
  );

  const handleScroll = useCallback(() => {
    const nearBottom =
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight - 10;

    if (hasNext && !loading && nearBottom) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasNext, loading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    getPosts(page);
  }, [page, getPosts]);

  useEffect(() => {
    setPage(1);
  }, [query, type, isVisible, isDarkMode]);

  return (
    <MainContent type={type}>
      <BackTop />
      {isActive && (
        <div>
          <Link to={`/post/new/${type}`}>new</Link>
          {isAdmin && (
            <Switch
              style={{ float: "right" }}
              size="small"
              onChange={() => setIsVisible(!isVisible)}
              checkedChildren="비공개"
              unCheckedChildren="공개"
            />
          )}
        </div>
      )}
      <YearBreadcrumb type={type} />
      <SearchBar type={type} />
      <Divider />
      {loading && page === 1 ? (
        <>
          <Skeleton active paragraph={{ rows: 5 }} />
          <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton active paragraph={{ rows: 10 }} />
        </>
      ) : (
        posts.map((post, idx) => (
          <PostCard key={`post-${idx}`} post={post} type={type} />
        ))
      )}
      {hasNext && loading && <Skeleton />}
    </MainContent>
  );
};

export default PostList;
