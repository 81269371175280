import mipong from "../../assets/images/mipong.png";

import { BackTop, Col, Divider, Row, Skeleton } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AppContext } from "../..";
import { apiAxios } from "../../api";
import PostDetailStyle from "../../assets/styles/post-detail-style";
import { Post } from "../../dto/Post";
import { showMessage, showSuccessMessage } from "../../util";
import MarkdownViewer from "../common/MarkdownViewer";
import WysiwygViewer from "../common/WysiwygViewer";
import PDFViewer from "../common/PDFViewer";
import { Content } from "antd/lib/layout/layout";

const PostContent = ({ match }: any) => {
  const { postId } = useMemo(() => match.params, [match.params]);
  const { isAdmin, isDarkMode, isMobile } = useContext(AppContext);
  const [post, setPost] = useState<Post>();

  const getPost = async () => {
    setPost(undefined);
    const { data } = await apiAxios
      .post(`/api/post/${postId}/content`)
      .catch((error: any) => {
        showMessage(error.response.data.data);
        window.location.href = "/";

        return error.response.data;
      });
    setPost(data.data.post);
  };

  const sharePost = () => {
    const url = `${window.location.origin}/share/post/${post?.id}/content`;
    navigator.clipboard.writeText(url);

    showSuccessMessage("Share Link Copied");
  };

  const withSummary = (contents: string) => {
    return ["...", contents, "..."].join("");
  };

  const getSummary = (contents: string) => {
    const replaced = contents.replace(/&nbsp/g, "");

    const longSentence = replaced.match(/[\s,?가-힣a-zA-Z0-9]{30,}\./);

    if (longSentence) {
      return longSentence[0] + "..";
    }

    const shortSentence = replaced.match(/[\s,?가-힣a-zA-Z0-9]{10,}\./);

    if (shortSentence) {
      return shortSentence[0] + "..";
    }

    const firstWord = replaced.match(/[\s,?가-힣a-zA-Z0-9]{30,}/);

    if (firstWord) {
      return withSummary(firstWord[0]);
    }

    const korean = replaced.match(/[\s,?가-힣]{5,}/);
    if (korean) {
      return withSummary(korean[0]);
    }

    return withSummary(contents.slice(0, 30));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPost();
  }, [postId, isDarkMode]);

  return (
    <Content style={{ padding: "0 10px" }}>
      <Row>
        <Col xs={0} md={4} lg={4} xl={4} />
        <Col xs={24} md={16} lg={16} xl={16}>
          <Helmet>
            <title>{post?.title}</title>
            <meta property="og:title" content={post?.title} />
            <meta
              property="og:description"
              content={getSummary(post?.contents ?? "")}
            />
            <meta property="og:image" content={mipong} />
          </Helmet>
          <PostDetailStyle />
          <BackTop />
          <h2 style={{ margin: "5px 0px" }}>{post?.title}</h2>
          <div>
            <span style={{ float: "right" }}>
              {post?.regdate.slice(0, 10).replace(/-/g, "")}
            </span>
          </div>
          {post && (
            <div>
              <a onClick={sharePost}>공유하기</a>
              {isAdmin && (
                <>
                  &nbsp; | &nbsp;
                  <a href={`/post/${post.post_type}/${post.id}`}>원문보기</a>
                </>
              )}

              {post.contents_type === "pdf" && (
                <>
                  &nbsp; | &nbsp;
                  <a href={post.file_url}>다운로드</a>
                </>
              )}
            </div>
          )}
          <Divider />
          {post ? (
            <>
              {post.contents_type.startsWith("wysiwyg") ? (
                <WysiwygViewer
                  theme={isDarkMode ? "dark" : "light"}
                  contents={post.contents}
                />
              ) : post.contents_type.startsWith("markdown") ? (
                <MarkdownViewer
                  theme={isDarkMode ? "dark" : "light"}
                  markdown={post.contents}
                />
              ) : (
                <PDFViewer path={post.file_url} />
              )}

              <h5 style={{ float: "right" }}>조회수 {post?.view_count}</h5>
            </>
          ) : (
            <>
              <Skeleton active paragraph={{ rows: 5 }} />
              <Skeleton active paragraph={{ rows: 3 }} />
              <Skeleton active paragraph={{ rows: 10 }} />
            </>
          )}
        </Col>
        <Col xs={0} md={4} lg={4} xl={4}></Col>
      </Row>
    </Content>
  );
};

export default PostContent;
