import "styled-components";
import { createGlobalStyle } from "styled-components";

const PostDetailStyle = createGlobalStyle`
  #index {
    background-color: transparent !important;
    position: fixed !important;
    left: 80%;
    top: 0;
    overflow-y:scroll;
    height: 100%
  }
  #index::-webkit-scrollbar {
      display: none;
  }
  #index > a {
    color: #4B96E6 !important;
    text-decoration: none !important;
  }
  @media only screen and (max-width:500px) {
    #index {
      display: none;
    }
  }
  @media only screen and (min-width:501px) and (max-width:1400px) {
    #index {
      display: none;
    }
  }
`;
export default PostDetailStyle;
