import React, { useContext } from "react";
import Giscus from "@giscus/react";
import { AppContext } from "../..";

interface GiscusCommentsProps {
  postId: string | number;
}

const GiscusComments: React.FC<GiscusCommentsProps> = ({ postId }) => {
  const { isDarkMode } = useContext(AppContext);

  return (
    <Giscus
      id={`giscus-comments-${postId}`}
      repo="h16rkim/blog-comments" // Format: username/reponame
      repoId="R_kgDOOQnANw"
      category="Comments" // Discussions category
      categoryId="DIC_kwDOOQnAN84Cokmm"
      mapping="specific" // How to map comments to URL
      term={postId.toString()}
      reactionsEnabled="1"
      emitMetadata="0"
      inputPosition="bottom"
      theme={isDarkMode ? "dark" : "light"}
      lang="ko"
      loading="eager"
    />
  );
};

export default GiscusComments;
