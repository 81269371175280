import "styled-components";
import styled from "styled-components";

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const NavigationSide = styled.div<{ align?: "left" | "right" }>`
  flex: 1;
  margin-right: ${(props) => (props.align === "right" ? "0" : "10px")};
  margin-left: ${(props) => (props.align === "right" ? "10px" : "0")};

  @media (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const PostTitle = styled.h3`
  margin: 10px 0 0px 0;
  color: #4b96e6;
  font-size: 1.1em;
  text-decoration: underline;
`;

export const PostSummary = styled.p`
  margin: 0;
  color: #666;
  font-size: 0.9em;
`;
